/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(
    './model.gltf'
  )
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Mesh_bread.geometry}
        material={materials.brown}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_bread_1.geometry}
        material={materials.brownLight}
        castShadow
        receiveShadow
      />
    </group>
  )
}

useGLTF.preload(
  './model.gltf'
)
